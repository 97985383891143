import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IAgilityProps } from "utils/interfaces";

export const agilityUtilStates: IAgilityProps = {
    modal: {status: false, type: "", modalObject: {}},
    // openModal,
    // closeModal: () => Function,
    announcement: "hello",
}

export const agilitySlice = createSlice({
    name: "agility-util-slice",
    initialState: agilityUtilStates,
    reducers: {
        openModal: (state, action: PayloadAction<{type: string, modalObj?: any}>) => {
            state.modal = {status: true, type: action.payload.type, modalObject: action.payload.modalObj}
        },
        closeModal: (state) => {
            state.modal = {status: false, type: "", modalObject: {}}
        },
        setAnnouncement: (state, action: PayloadAction<string>) => {
            state.announcement = action.payload
        }
    }
})

export const agilityActions = agilitySlice.actions
export const agilityReducer = agilitySlice.reducer