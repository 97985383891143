// src/components/ForgotPassword.tsx
import { back_arrow, btn_arrow_right_white } from 'assets';
import { Button } from 'components';
import { TextInput } from 'components/inputs/TextInput';
import { useMutation } from '@tanstack/react-query';
import { useState } from 'react';
import { useNavigate } from 'react-router';
import { forgotPasswordApi } from 'apiRequest/auth/authentication';
import { useDispatch } from 'react-redux';
import { setEmail } from 'store/slices/authLogin/authSlice';

export const ForgotPassword = () => {
  const [email, setEmailLocal] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { mutate } = useMutation({
    mutationFn: forgotPasswordApi,
    onError: (error: Error) => {
      console.log("error=== ", error);
    },
    onSuccess: (res: any) => {
      console.log("res---- ", res);
      if (res) {
        sessionStorage.setItem('agility_token', res.data['access-token']);
      }
      dispatch(setEmail(email));
      navigate("/reset-password-otp");
    }
  });

  const sendOtp = () => {
    console.log("login fxn clicked");
    mutate({ email });
  };

  return (
    <div className="px-2">
      <p className='mb-6 text-[0.831rem] tb:text-[1rem] font-[500] text-col_525252'>
        No worries, let's reset your password. First enter your email below
      </p>
      <TextInput
        label='Email'
        placeholder='Enter your email'
        value={email}
        setValue={setEmailLocal}
        onChange={(e) => setEmailLocal(e.target.value)}
        className="w-full mb-6"
      />
      <Button
        text="proceed"
        suffixIcon={btn_arrow_right_white}
        onClick={() => sendOtp()}
        className='w-full mt-8 text-col_ffffff px-6 py-4 flex items-center justify-center cursor-pointer text-[0.83rem] font-[600] rounded-[0.475rem] bg-col_e84e40'
      />
      <div className='flex items-center justify-center gap-2 mt-6 '>
        <img src={back_arrow} alt="" className='cursor-pointer' onClick={() => navigate(-1)} />
        <span className='cursor-pointer font-fontInter text-txt_087_normal text-[#525252]' onClick={() => navigate(-1)}>Back to login</span>
      </div>
    </div>
  );
}
