import { useState } from 'react'
import { SidebarDropdown } from './SidebarDropdown'
import { useNavigate } from 'react-router-dom'
import { ISidebarSubmenuItem } from 'utils/interfaces'

type ISidebarMenu = {
  category?: string
  feature: string
  mainRoute: string
  img: string
  active: string
  subMenu: ISidebarSubmenuItem[]
  openSubMenu: boolean
  setOpenSubMenu: (e: boolean) => void
}

export const SidebarMenu = ({ mainRoute, feature, img, active, subMenu, openSubMenu}: ISidebarMenu) => {
  const navigate = useNavigate()
  
  const [open, setOpen] = useState("")
  const [activePage, setActivePage] = useState("")

  return (
    <div className='relative'>
      <main 
        className={`sidebar-menu relative`}
        onClick={() => {
          if (!subMenu.length) {
            navigate(mainRoute)
          }
        }}
      >
        <div
          className={`relative ${openSubMenu ? "mb-0" : "mb-2"} h-[2.75rem] flex items-center gap-2 pl-4 cursor-pointer hover:border-red-500 hover:border-r-4
            ${active === feature && "border-red-500 border-r-4"}
          `}
          onClick={() => {
            if (open === feature) setOpen("")
            else setOpen(feature)
          }}
        >
          <img src={img} alt="" />
          <span className='capitalize text-[12.5px] leading-[15.13px]'>{feature}</span>
        </div>
      </main>
      <div className={`cont relative ${(subMenu && subMenu?.length > 0) && open === feature ? "open-side-dropdown animate-sidebar-menu" : " hidden"}`}>
          {subMenu && subMenu.map((item, i) => 
          (
            <SidebarDropdown 
              key={i}
              id={i}
              item={item}
              newMenu={item?.newMenu}
              mainRoute={mainRoute}
              subRoute={item.subRoute}
              open={open}
              activePage={activePage}
              setActivePage={setActivePage}
            />
          )
          )}
        </div>
      
    </div>
  )
}
