import { searchIcon } from 'assets'


interface ITrackWaybillProps {
    onChange: (e: any) => void
    value: string
    className: string
    inputWrapperClass?: string
}

export const TrackWaybill = ({onChange, value, className, inputWrapperClass, ...rest}: ITrackWaybillProps) => {
  return (
    <div className={`flex items-center ${className}`}>
        <img src={searchIcon} alt="" />
        <input
            {...rest}
            placeholder='Track Waybill'
            type={"text"}
            autoComplete="off"
            className={`w-full bg-transparent outline-0 px-3 text-[0.875rem] leading-normal font-normal ${inputWrapperClass}`}
        />
    </div>
  )
}