import { forgot_password_gif, gig_logistics, otp_sent_gif, set_password_gif } from 'assets'
import { useLocation } from 'react-router'
import { Login } from './Login'
import { ForgotPassword } from './ForgotPassword'
import { ResetPassword } from './ResetPassword'
import { ROUTES } from 'utils/routesEnums'
import { ResetPasswordOtp } from './ResetPasswordOtp'


export const AuthenticationLayout = () => {
  const {pathname} = useLocation()
  

  return (
    <div className='auth-page w-screen h-screen bg-black flex items-start justify-center md:justify-end pt-[10vh] md:pr-[5rem]'>
        <div className='w-[90%] tb:w-[40%] lg:w-[32%] h-auto flex flex-col items-center pt-2 tb:pt-3 pb-8 px-2 tb:px-3 md:mr-8 bg-col_fff rounded-[0.95rem]'>
            <div className='bg-col_020607 py-[3rem] tb:py-[4rem] w-full rounded-[0.83rem] flex flex-col items-center justify-center'>
              <img 
                src={
                  pathname === ROUTES.home ? gig_logistics :
                  pathname === ROUTES.forgot_password ? forgot_password_gif :
                  pathname === ROUTES.reset_password ? set_password_gif :
                  pathname === ROUTES.reset_password_otp ? otp_sent_gif :
                  ""
                } 
                alt="" 
                className='w-[5rem] '
              />
              <p className='text-[0.85rem] text-col_ffffff'>
                {pathname === ROUTES.reset_password ? "Set a new password" : 
                 pathname === ROUTES.forgot_password ? "Forgot Password?" :
                 pathname === ROUTES.reset_password_otp ? "OTP Sent" :
                ""}
              </p>
            </div>
            <section className=' mt-4 w-full h-auto'>
              {pathname === ROUTES.home && <Login />}
              {pathname === ROUTES.forgot_password && <ForgotPassword />}
              {pathname === ROUTES.reset_password_otp && <ResetPasswordOtp />}
              {pathname === ROUTES.reset_password && <ResetPassword />}
            </section>
        </div>
    </div>
  )
}