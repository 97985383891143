import { useState } from 'react'
import { SidebarMenu } from './SidebarMenu'
import { sidebarData } from './sidebarData'
import { TrackWaybill } from 'components/trackWaybill/TrackWaybill'
import { logo } from 'assets'
import { useNavigate } from 'react-router'

type Props = {
    sidebar: boolean
    className?: string
}
 
export const Sidebar = ({ className}: Props) => {
    const navigate = useNavigate()
    const [activeMenu] = useState("")
    const [open, setOpen] = useState(false)
    const [input, setInput] = useState("")
    

  return (
    <div className={`w-full h-full relative  overflow-x-hidden text-white box-border ${className}`}>
        <div className="w-[14rem] bg-col_141414 fixed top-0 right-0 left-0 h-[7vh] pl-6 md:pl-0 flex items-center justify-center z-50">
			<img
				src={logo}
				alt="logo"
				onClick={() => navigate('/')}
				className="cursor-pointer "
			/>
		</div>

        <div className='w-full mb-[1rem] mt-[7vh] pt-6 overflow-y-auto '>
            <TrackWaybill
                value={input}
                onChange={(e) => setInput(e.target.value)}
                className='w-[80%] mx-auto h-[2.18rem] mb-6 bg-col_282828 rounded-[2rem] px-4 '
                inputWrapperClass='text-[0.75rem] italic font-normal text-col_bababa py-2'
            />
            <div className="menu-container">
                {sidebarData?.map((item, i) => (
                    <div className="side " key={i}>
                        {item.category && (
                        <p className='relative pl-4 uppercase text-[9px] leading-[10.89px] font-[600] text-col_bababa py-2 my-2'>
                            {item.category}
                        </p>)}
                        <SidebarMenu 
                            feature={item.feature} 
                            img={item.image} 
                            mainRoute={item.mainRoute}
                            subMenu={item.subMenu} 
                            active={activeMenu} 
                            openSubMenu={open}
                            setOpenSubMenu={setOpen}
                        />
                    </div>
                )) }
            </div>
        </div>
    </div>
  )
}