import { lazy } from 'react';

export const Pages = {
    Dashboard:  lazy(() => 
        import('pages/dashboard/Dashboard').then((module) => ({
          default: module.Dashboard
    }))),
    
    CaptureShipments: lazy(() => 
        import('pages/shipments/capture-shipments/CaptureShipments').then((module) => ({
          default: module.CaptureShipments
    }))),

    Notifications: lazy(() => 
      import('pages/Notifications').then((module) => ({
        default: module.Notifications
    }))),
  
    PaymentOptions: lazy(() => 
      import('pages/shipments/capture-shipments/PaymentOptions').then((module) => ({
        default: module.PaymentOptions
      }))
    )
}