import { Suspense } from 'react';
import { Layout } from 'components/layout/Layout';
import { BrowserRouter, Routes, Route} from 'react-router-dom';
import {Provider} from "react-redux"
import { AuthenticationLayout } from 'pages/authentication/AuthenticationLayout';
import { store } from './store/store';
import { Pages } from 'Pages';


function App() {
   
  return (
    <Provider store={store}>
       <Suspense fallback={<div>Loading...</div>}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<AuthenticationLayout /> }/>
          <Route path="/forgot-password" element={<AuthenticationLayout /> }/>
          <Route path="/reset-password-otp" element={<AuthenticationLayout /> }/>
          <Route path="/reset-password" element={<AuthenticationLayout /> }/>
            <Route 
              path="/dashboard" 
              element={ 
                <Layout> <Pages.Dashboard /> </Layout>
              }
            />
            <Route 
                path='/notifications'
                element={
                  <Layout><Pages.Notifications /></Layout>
                }
            />
            <Route 
                path='/shipments/capture-shipments'
                element={
                  <Layout><Pages.CaptureShipments /></Layout>
                }
            />
            <Route 
                path='/shipments/capture-shipments/payment'
                element={
                  <Layout><Pages.PaymentOptions /></Layout>
                }
            />
          
        </Routes>
      </BrowserRouter>
      </Suspense>
    </Provider>
  );
}

export default App;