import {
  // adminOptionsIcon,
  dashboardIcon,
  // ecommerceIcon,
  // itSupportIcon,
  // personalShoppingIcon,
  // reportsIcon,
  // salesIcon,
  shipmentIcon,
  // storeKeepingIcon,
  userManagementIcon,
  // world_explore_icon,
  // giggi_processing_icon,
  // hub_processing_icon,
  // accounts_icon,
  // flight_icon,
  // truck,
  // location,
  // delivery_manifest_icon,
  // viva_icon,
  // sales_rep_icon,

} from 'assets';
import { ISidebarSubmenuItem } from 'utils/interfaces';

interface SideBar {
  feature: string;
  image: string;
  mainRoute: string;
  category?: string;
  subMenu: ISidebarSubmenuItem[];
}

export const sidebarData: SideBar[] = [
  {
    feature: 'dashboard',
    image: dashboardIcon,
    mainRoute: '/dashboard',
    category: '',
    subMenu: [],
  },
  
  {
    feature: 'user management',
    image: userManagementIcon,
    mainRoute: '/user-management',
    category: 'configuration options',
    subMenu: [
      { title: 'users', subRoute: 'users' },
      { title: 'customers', subRoute: 'customers' },
      { title: 'partners', subRoute: 'partners' },
      { title: 'GIGL Roles', subRoute: 'gigl-roles' },
      { title: 'system roles', subRoute: 'system-roles' },
      { title: 'access points', subRoute: 'access-points' },
      { title: 'wallet', subRoute: 'wallet' },
    ],
  },

  {
    category: 'shipment processing',
    feature: 'shipments',
    image: shipmentIcon,
    mainRoute: '/shipments',
    subMenu: [
      { title: 'capture shipments', subRoute: 'capture-shipments' },
      { title: 'capture intl shipments', subRoute: 'capture-intl-shipments' },
      {
        title: 'home delivery service',
        subRoute: 'home-delivery-service',
        newMenu: true,
      },
      { title: 'daily sales', subRoute: 'daily-sales' },
      {
        title: 'global daily sales',
        subRoute: 'global-daily-sales'
      },
      {
        title: 'staff reroute shipment',
        subRoute: 'staff-reroute-shipment'
      },
      {
        title: 'staff reroute management',
        subRoute: 'staff-reroute-management'
      },
      { title: 'return shipment', subRoute: 'create-return-shipment' },
      // {title: "packing list", subRoute: "packing-list"},
      { title: 'expenditure', subRoute: 'expenditure' },
      { title: 'overdue shipments', subRoute: 'overdue-shipments' },
      {
        title: 'ecommmerce overdue shipemts',
        subRoute: 'ecommerce-overdue-shipments',
      },
      { title: 'COD processing order', subRoute: 'cod-processing-order' },
      {
        title: 'demurrage processing order',
        subRoute: 'demurrage-processing-order',
      },
      { title: 'mobile payments', subRoute: 'mobile-payments' },
      { title: 'bank processing order', subRoute: 'bank-processing-order' },
      { title: 'validate waybill', subRoute: 'validate-waybill' },
      { title: 'transfer management', subRoute: 'transfer-management' },
      { title: 'pay for shipment', subRoute: 'pay-for-shipment' },
      { title: 'users', subRoute: 'users' },
    ],
  },

  // {
  //   feature: 'admin options',
  //   image: adminOptionsIcon,
  //   mainRoute: '/admin-options',
  //   category: 'admin',
  //   subMenu: [
  //     { title: 'country', subRoute: 'country' },
  //     { title: 'region', subRoute: 'region' },
  //     { title: 'state', subRoute: 'state' },
  //     { title: 'zones', subRoute: 'zone' },
  //     { title: 'stations', subRoute: 'station' },
  //     { title: 'service centres', subRoute: 'service-centre', newMenu: true },
  //     { title: 'store', subRoute: 'store' },
  //     { title: 'Shipment Locations', subRoute: 'shipment-location' },
  //     { title: 'Place Locations', subRoute: 'place-location' },
  //     { title: 'Banks', subRoute: 'bank' },
  //     {
  //       title: 'Region SC Mapping',
  //       subRoute: 'region-sc-mapping',
  //       newMenu: true,
  //     },
  //     { title: 'Route Zone Mapping', subRoute: 'route-zone-mapping' },
  //     {
  //       title: 'Country Route Zone Mapping',
  //       subRoute: 'country-route-zone-mapping',
  //     },
  //     { title: 'Delivery Options', subRoute: 'delivery-option' },
  //     { title: 'Delivery Option Prices', subRoute: 'delivery-option-prices' },
  //     {
  //       title: 'Delivery Location Prices',
  //       subRoute: 'delivery-location-prices',
  //     },
  //     {
  //       title: 'Special Packages',
  //       subRoute: 'special-packages',
  //     },
  //     {
  //       title: 'Special Zone Prices',
  //       subRoute: 'special-zone-prices',
  //     },
  //     {
  //       title: 'Regular Zone Prices',
  //       subRoute: 'regular-zone-prices',
  //     },
  //     {
  //       title: 'Price Update',
  //       subRoute: 'price-update',
  //     },
  //     {
  //       title: 'Price Category',
  //       subRoute: 'price-category',
  //     },
  //     {
  //       title: 'Haulage',
  //       subRoute: 'haulage',
  //     },
  //     {
  //       title: 'Haulage Distance',
  //       subRoute: 'haulage-distance',
  //     },
  //     {
  //       title: 'Haulage Price',
  //       subRoute: 'haulage-price',
  //     },
  //     {
  //       title: 'Insurance',
  //       subRoute: 'insurance',
  //     },
  //     {
  //       title: 'VAT',
  //       subRoute: 'vat',
  //     },
  //     {
  //       title: 'Shipment Packaging Price',
  //       subRoute: 'shipment-package-price',
  //     },
  //     {
  //       title: 'Weight Limit',
  //       subRoute: 'weight-limit',
  //     },
  //     {
  //       title: 'Weight Limit Prices',
  //       subRoute: 'weight-limit-prices',
  //     },
  //     {
  //       title: 'Messaging',
  //       subRoute: 'messaging',
  //       childMenu: [
  //         {
  //           title: 'Email',
  //           childRoute: 'email',
  //         },
  //         {
  //           title: 'SMS',
  //           childRoute: 'sms',
  //         },
  //         {
  //           title: 'SMS Send Log',
  //           childRoute: 'sms-send-log',
  //         },
  //         {
  //           title: 'Email Send Log',
  //           childRoute: 'email-send-log',
  //         },
  //         {
  //           title: 'Notification',
  //           childRoute: 'notification',
  //         },
  //         {
  //           title: 'SLA',
  //           childRoute: 'sla',
  //         },
  //       ],
  //     },
  //     {
  //       title: 'Scan Status',
  //       subRoute: 'scan-status',
  //     },
  //     {
  //       title: 'Log Vist Reason',
  //       subRoute: 'log-visit-reason',
  //     },
  //     {
  //       title: 'Global Property',
  //       subRoute: 'global-property',
  //     },
  //     {
  //       title: 'Expense Type',
  //       subRoute: 'expense-type',
  //     },
  //     {
  //       title: 'Partners',
  //       subRoute: 'partners',
  //     },
  //     {
  //       title: 'Credit Partners',
  //       subRoute: 'credit-partner',
  //     },
  //     {
  //       title: 'Fleet Options',
  //       subRoute: 'fleet-options',
  //       childMenu: [
  //         {
  //           title: 'Fleet Make',
  //           childRoute: 'fleet-make',
  //         },
  //         {
  //           title: 'Fleet Model',
  //           childRoute: 'fleet-model',
  //         },
  //         {
  //           title: 'Fleets',
  //           childRoute: 'fleets',
  //         },
  //       ],
  //     },
  //     {
  //       title: 'Customer Options',
  //       subRoute: 'customer-options',
  //       childMenu: [
  //         {
  //           title: 'Individual Customer',
  //           childRoute: 'individual-customer',
  //         },
  //         {
  //           title: 'Account Customer',
  //           childRoute: 'account-customer',
  //         },
  //       ],
  //     },
  
  //     {
  //       title: 'Devices',
  //       subRoute: 'devices',
  //     },
  //     {
  //       title: 'Device Management',
  //       subRoute: 'device-management',
  //     },
  //     {
  //       title: 'Enterprise Partners',
  //       subRoute: 'enterprise-partners',
  //     },
  //     {
  //       title: 'Captain Bonus Mapping',
  //       subRoute: 'captain-bonus-mapping',
  //     },
  //     {
  //       title: 'Pay for Shipment',
  //       subRoute: 'pay-for-shipment-by-wallet',
  //     },
  //     {
  //       title: 'Generate Coupon Points',
  //       subRoute: 'generate-coupon',
  //     },
  //     {
  //       title: 'Captain Transactions',
  //       subRoute: 'captain-transactions',
  //     },
  //     {
  //       title: 'Upload Wallet',
  //       subRoute: 'upload-wallet',
  //     },
  //     {
  //       title: 'Go Faster Report',
  //       subRoute: 'go-faster-report',
  //     },
  //     {
  //       title: 'Validate Bills Payment',
  //       subRoute: 'validate-bills-payment',
  //     },
  //     {
  //       title: 'Bills Payment Summary',
  //       subRoute: 'bills-payment-summary',
  //     },
  //     {
  //       title: 'Payment Method',
  //       subRoute: 'payment-method',
  //     },
  //     {
  //       title: 'Audit Logs',
  //       subRoute: 'audit-logs',
  //     },
  //   ],
  // },

  // {
  //   feature: 'reports',
  //   image: reportsIcon,
  //   mainRoute: '/reports',
  //   category: 'executive report',
  //   subMenu: [
  //     {
  //       title: 'Bills Payment',
  //       subRoute: 'bills-payment-transactions',
  //     },
  //     {
  //       title: 'Wallet Funding',
  //       subRoute: 'wallet-funding-summary',
  //     },
  //     {
  //       title: 'Summary Report',
  //       subRoute: 'summary-report',
  //     },
  //   ],
  // },

  // {
  //   feature: 'customer care',
  //   image: reportsIcon,
  //   mainRoute: '/customer-care',
  //   category: 'Customer care',
  //   subMenu: [
  //     {
  //       title: 'GIGLS Tracker',
  //       subRoute: 'shipment-tracker'
  //     },
  //     {
  //       title: 'Quick Quotes',
  //       subRoute: 'quick-quotes'
  //     },
  //     {
  //       title: 'Int’l Quick Quotes',
  //       subRoute: 'intl-quotes'
  //     },
  //     {
  //       title: 'Customers',
  //       subRoute: 'customers'
  //     },
  //     {
  //       title: 'Customer Delivery Code',
  //       subRoute: 'delivery-code'
  //     },
  //     {
  //       title: 'Incidence Reports',
  //       subRoute: 'incidence-report'
  //     },
  //     {
  //       title: 'Overdue Shipments',
  //       subRoute: 'overdue-shipments'
  //     },
  //     {
  //       title: 'Ecommerce Overdue Shipments',
  //       subRoute: 'ecommerce-overdue-shipments'
  //     },
  //     {
  //       title: 'Shipment Manifests',
  //       subRoute: 'shipment-manifests'
  //     },
  //     {
  //       title: 'SMS Delivery Log',
  //       subRoute: 'sms-delivery-log'
  //     },
  //     {
  //       title: 'Mobile Pickup Request',
  //       subRoute: 'mobile-pickup-request'
  //     },
  //     {
  //       title: 'Shipment Contact ',
  //       subRoute: 'shipment-contact'
  //     },
  //     {
  //       title: 'Wallet Payment Log ',
  //       subRoute: 'wallet-payment-log'
  //     },
  //     {
  //       title: 'Validate Bills Payment',
  //       subRoute: 'validate-bills-payments'
  //     },
  //     {
  //       title: 'Send Message to customers',
  //       subRoute: 'send-message-to-customers'
  //     },
  //   ]
  // },

  // {
  //   feature: 'Region',
  //   image: location,
  //   mainRoute: '/region',
  //   category: 'Region',
  //   subMenu: [
  //     {
  //       title: 'Bank Deposit for Shipments',
  //       subRoute: 'bank-deposit-for-shipments',
  //     },
  //     { title: 'Bank Deposit for COD', subRoute: 'bank-deposit-for-cod' },
  //     {
  //       title: 'Bank Deposit for Demurrage',
  //       subRoute: 'bank-deposit-for-demurrage',
  //     },
  //     { title: 'Shipment Contact', subRoute: 'shipment-contact' },
  //     {
  //       title: 'Delivery Process Monitoring',
  //       subRoute: 'delivery-process-monitoring',
  //     },
  //     { title: 'Quick Quotes', subRoute: 'quick-quotes' },
  //     {
  //       title: 'Customer Delivery Code',
  //       subRoute: 'customer-delivery-number',
  //     },
  //     { title: 'Regional Shipment Sales', subRoute: 'regional-shipment-sales' },
  //     {
  //       title: 'Mobile Pickup Request',
  //       subRoute: 'pickup-request',
  //     },
  //     { title: 'Go Faster Report', subRoute: 'go-faster-report' },
  //     { title: 'Place Location', subRoute: 'place-location' },
  //     { title: 'Rider Delivery Report', subRoute: 'rider-delivery-report' },
  //     { title: 'Delivery Manifest', subRoute: 'delivery-manifest-by-waybill' },
  //   ],
  // },

  // {
  //   feature: 'fleet center',
  //   image: truck,
  //   mainRoute: '/fleet-center',
  //   category: 'Fleet Center',
  //   subMenu: [
  //     { title: 'Fleet Tracker', subRoute: 'fleet-tracker' },
  //     { title: 'Fleet Dispatch', subRoute: 'fleet-dispatch' },
  //   ],
  // },

  // {
  //   feature: 'IT support',
  //   image: itSupportIcon,
  //   mainRoute: '/it-support',
  //   category: 'it support',
  //   subMenu: [
  //     {
  //       title: 'Staff',
  //       subRoute: 'users',
  //     },
  //     {
  //       title: 'Customers',
  //       subRoute: 'customer-management',
  //     },
  //     {
  //       title: 'Partners',
  //       subRoute: 'partner-management',
  //     },
  //     {
  //       title: 'Shipment Tracker',
  //       subRoute: 'shipment-tracker',
  //     },
  //     {
  //       title: 'Wallet Payment Log',
  //       subRoute: 'wallet-payment-log',
  //     },
  //     {
  //       title: 'Messaging',
  //       subRoute: 'messaging',
  //       childMenu: [
  //         {
  //           title: 'Email Send Log',
  //           childRoute: 'email-send-log',
  //         },
  //         {
  //           title: 'SMS Send Log',
  //           childRoute: 'sms-send-log',
  //         },
  //       ],
  //     },
  //     {
  //       title: 'Shipment Location',
  //       subRoute: 'shipment-location',
  //     },
  //     {
  //       title: 'Upload Wallet',
  //       subRoute: 'upload-wallet',
  //     },
  //     {
  //       title: 'Validate Bills Payment',
  //       subRoute: 'validate-bills-payment',
  //     },
  //     {
  //       title: 'Bill Payment Summary',
  //       subRoute: 'bills-payment-summary',
  //     },
  //     {
  //       title: 'Service Centers',
  //       subRoute: 'service-centre',
  //     },
  //     {
  //       title: 'Store',
  //       subRoute: 'store',
  //     },
  //     {
  //       title: 'Place Location',
  //       subRoute: 'place-location',
  //     },
  //     {
  //       title: 'Region SC Mapping',
  //       subRoute: 'region-sc-mapping',
  //     },
  //     {
  //       title: 'Global Property',
  //       subRoute: 'global-property',
  //     },
  //     {
  //       title: 'Utility Support',
  //       subRoute: 'utility-support',
  //     },
  //     {
  //       title: 'Delivery Number',
  //       subRoute: 'delivery-number',
  //     },
  //     {
  //       title: 'Quick Quotes',
  //       subRoute: 'quick-quotes',
  //     },
  //     {
  //       title: 'Int. Quick Quotes',
  //       subRoute: 'int-quick-quotes',
  //     },
  //     {
  //       title: 'Wallet Option',
  //       subRoute: 'wallet-options',
  //       childMenu: [
  //         {
  //           title: 'Wallet',
  //           childRoute: 'wallet',
  //         },
  //         {
  //           title: 'Wallet Transactions',
  //           childRoute: 'wallet-transactions',
  //         },
  //         {
  //           title: 'Wallet Credit History',
  //           childRoute: 'wallet-credit-history',
  //         },
  //       ],
  //     },
  //     {
  //       title: 'Collection Center',
  //       subRoute: 'shipment-collection',
  //     },
  //     {
  //       title: 'Collected Shipmemt',
  //       subRoute: 'collected-shipments',
  //     },
  //     {
  //       title: 'Customer Delivery Number',
  //       subRoute: 'customer-delivery-number',
  //     },
  //   ],
  // },

  // {
  //   category: 'ecommerce',
  //   feature: 'ecommerce',
  //   image: ecommerceIcon,
  //   mainRoute: '/ecommerce',
  //   subMenu: [
  //     { title: 'account customer', subRoute: 'account-customer' },
  //     { title: 'shipment report', subRoute: 'shipment-report' },
  //     {
  //       title: 'class customer management',
  //       subRoute: 'class-customer-management',
  //     },
  //     { title: 'create customer', subRoute: 'create-customer' },
  //     { title: 'ecommerce overdue', subRoute: 'ecommerce-overdue' },
  //     {
  //       title: 'pending ecommerce request',
  //       subRoute: 'pending-ecommerce-request',
  //     },
  //     { title: 'COD shipments', subRoute: 'cod-shipments' },
  //     { title: 'generate report', subRoute: 'generate-report' },
  //     { title: 'assigned customers', subRoute: 'assigned-customers' },
  //     {
  //       title: 'overall assigned customers',
  //       subRoute: 'overall-assigned-customers',
  //     },
  //     {
  //       title: 'assign Class Subscription',
  //       subRoute: 'assign-class-subscription',
  //     },
  //     {
  //       title: 'ecommerce delayed delivery report',
  //       subRoute: 'ecommerce-delayed-delivery-report',
  //     },
  //     { title: 'COD collection deposit', subRoute: 'cod-collection-deposit' },
  //     { title: 'delayed delivery', subRoute: 'delayed-delivery-report' },
  //     {
  //       title: 'confirm COD wallet transfer',
  //       subRoute: 'confirm-cod-wallet-transfer',
  //     },
  //     { title: 'COD shipment status', subRoute: 'cod-shipment-status' },
  //     { title: 'cellulant settlement', subRoute: 'cellulant-settlement' },
  //     {
  //       title: 'Cod Shipments Arf But Not Collected',
  //       subRoute: 'cod-shipments-arf-but-not-collected'
  //     }
  //   ],
  // },

  // {
  //   category: 'sales manager',
  //   feature: 'sales',
  //   image: salesIcon,
  //   mainRoute: '/sales',
  //   subMenu: [
  //     {
  //       title: 'Corporate',
  //       subRoute: 'corporate-customer',
  //     },
  //     {
  //       title: 'Customer Management',
  //       subRoute: 'customer-management',
  //     },
  //     {
  //       title: 'GIGLS Tracker',
  //       subRoute: 'gigls-tracker',
  //     },
  //     {
  //       title: 'Customers',
  //       subRoute: 'individual-customer',
  //     },
  //     {
  //       title: 'Corporate Transactions',
  //       subRoute: 'corporate-transactions',
  //     },
  //     {
  //       title: 'Corporate Invoices',
  //       subRoute: 'corporate-invoices',
  //     },
  //     {
  //       title: 'Special Billing Weight',
  //       subRoute: 'capture-shipment',
  //     },
  //     {
  //       title: 'Special Billing Default',
  //       subRoute: 'capture-special-billing-weight',
  //     },
  //   ],
  // },

  // {
  //   category: 'store keeping',
  //   feature: 'store keeping',
  //   image: storeKeepingIcon,
  //   mainRoute: '/store-keeping',
  //   subMenu: [
  //     {
  //       title: 'Capture Store Shipment',
  //       subRoute: 'capture-shipment',
  //     },
  //     {
  //       title: 'Store Shipments',
  //       subRoute: 'shipments',
  //     },
  //     {
  //       title: 'Package List',
  //       subRoute: 'inventory-management',
  //     },
  //     {
  //       title: 'Package Transactions',
  //       subRoute: 'inventory-transactions',
  //     },
  //   ],
  // },
 
  // {
  //   category: 'Processing & Packaging',
  //   feature: 'Processing & Packaging',
  //   image: storeKeepingIcon,
  //   mainRoute: '/processing-and-packaging',
  //   subMenu: [
  //     { title: 'Groups & Packages', subRoute: 'group-shipment-view' },
  //     { title: 'Group Overdue Shipm.', subRoute: 'group-overdue-shipment' },
  //     { title: 'Gateway Activity', subRoute: 'gateway-activity' },
  //     { title: 'manifest', subRoute: 'manifest' },
  //     { title: 'movement manifest', subRoute: 'movement-manifest' },
  //     {
  //       title: 'Expected Movement Manifest',
  //       subRoute: 'expected-movement-manifest',
  //     },
  //     { title: 'Quick Quote', subRoute: 'quick-quote' },
  //     {
  //       title: 'International Quick Quote',
  //       subRoute: 'international-quick-quote',
  //     },
  //     {
  //       title: 'Ecommerce Quick Quote',
  //       subRoute: 'ecommerce-quick-quote',
  //     },
  //     {
  //       title: 'Create Movement Manifest',
  //       subRoute: 'create-movement-manifest',
  //     },
  //     { title: 'Move to New Manifest', subRoute: 'move-to-new-manifest' },
  //     { title: 'Delivery Manifest', subRoute: 'delivery-manifest' },
  //     {
  //       title: 'Unsigned Delivery Manifest',
  //       subRoute: 'unsigned-delivery-manifest',
  //     },
  //     {
  //       title: 'Sign off Delivery Manifest',
  //       subRoute: 'sign-off-delivery-manifest',
  //     },
  //     { title: 'Super Manifest', subRoute: 'super-manifest' },
  //     { title: 'Create Super Manifest', subRoute: 'generate-super-manifest' },
  //     { title: 'Expected Shipments', subRoute: 'expected-shipments' },
  //     { title: 'Collection Centre', subRoute: 'collection-centre' },
  //     { title: 'Shipment collection', subRoute: 'shipment-collection' },
  //     { title: 'collected shipment', subRoute: 'collected-shipments' },
  //     { title: 'Incidence Report', subRoute: 'incidence-report' },
  //     {
  //       title: 'manifest visit monitoring',
  //       subRoute: 'manifest-visit-monitoring',
  //     },
  //     { title: 'reroute shipment', subRoute: 'reroute-shipment' },
  //     {title: 'Shipment Receiver Details', subRoute: 'shipment-receiver-details'},
  //     { title: 'SMS delivery log', subRoute: 'sms-delivery-log' },
  //     { title: 'inventory transactions', subRoute: 'inventory-transactions' },
  //     { title: 'service center packages', subRoute: 'service-center-packages' },
  //     { title: 'wallet payment log', subRoute: 'wallet-payment-log' },
  //     {
  //       title: 'wallet confirm merchant cod transfer',
  //       subRoute: 'wallet-confirm-merchant-cod-transfer',
  //     },
  //     { title: 'validate bills payment', subRoute: 'validate-bills-payment' },
  //     { title: 'route optimisation', subRoute: 'route-optimisation' },
  //     {
  //       title: 'Shipment Update',
  //       subRoute: 'shipment-update'
  //     },
  //     {
  //       title: 'Manifest & GroupWaybill View',
  //       subRoute: 'manifest-group-waybill-view'
  //     },
  //     {
  //       title: 'Gateway Dispatch Inflow',
  //       subRoute: 'manifest-comparison-for-gateways'
  //     }
  //   ],
  // },

  // {
  //   category: 'Hub Processing',
  //   feature: 'Hub Processing',
  //   image: hub_processing_icon,
  //   mainRoute: '/hub-processing',
  //   subMenu: [
  //     {
  //       title: 'Hub Manifest',
  //       subRoute: 'manifest',
  //       newMenu: true,
  //     },
  //     {
  //       title: 'Hub Delivery Report',
  //       subRoute: 'delivery-report',
  //     },
  //   ],
  // },

  // {
  //   feature: 'fleet management',
  //   image: reportsIcon,
  //   mainRoute: '/fleet-management',
  //   category: 'Fleet Management',
  //   subMenu: [
  //     {
  //       title: 'Captains',
  //       subRoute: 'captains',
  //       childMenu: [
  //         {
  //           title: 'View Captains',
  //           childRoute: 'view-captains',
  //         },
  //       ],
  //     },
  //     {
  //       title: 'Vehicles',
  //       subRoute: 'vehicles',
  //       childMenu: [
  //         {
  //           title: 'View Vehicles',
  //           childRoute: 'view-vehicles',
  //         },
  //         {
  //           title: 'Vehicle Details',
  //           childRoute: 'vehicle-details',
  //         },
  //         {
  //           title: 'Vehicle Analytics',
  //           childRoute: 'vehicle-analytics',
  //         },
  //         {
  //           title: 'Create Dispute',
  //           childRoute: 'create-dispute',
  //         },
  //         {
  //           title: 'Idle Vehicles',
  //           childRoute: 'idle-vehicles',
  //         },
  //         {
  //           title: 'Workshop Vehicles',
  //           childRoute: 'workshop-vehicles',
  //         },
  //         {
  //           title: 'Decommisioned Vehicles',
  //           childRoute: 'decommissioned-vehicles',
  //         },
  //       ],
  //     },
  //     {
  //       title: 'Job Cards',
  //       subRoute: 'job-cards',
  //       childMenu: [
  //         {
  //           title: 'Open Job Card',
  //           childRoute: 'open-job-card',
  //         },
  //         {
  //           title: 'View Job Cards',
  //           childRoute: 'view-job-cards',
  //         },
  //       ],
  //     },
  //     {
  //       title: 'Routes',
  //       subRoute: 'routes',
  //       childMenu: [
  //         {
  //           title: 'Add New Route',
  //           childRoute: 'add-new-route',
  //         },
  //         {
  //           title: 'View All Routes',
  //           childRoute: 'view-all-routes',
  //         },
  //       ],
  //     },
  //     {
  //       title: 'Dispatches',
  //       subRoute: 'dispatches',
  //       childMenu: [
  //         {
  //           title: 'GIGLS Tracker',
  //           childRoute: 'gigls-tracker',
  //         },
  //         {
  //           title: 'Vehicles Dispatched',
  //           childRoute: 'vehicles-dispatched',
  //         },
  //         {
  //           title: 'Vehicles Operations Status',
  //           childRoute: 'vehicle-operation-status',
  //         },
  //         {
  //           title: 'Dispatches Completed By Vehicle',
  //           childRoute: 'dispatch-completed-by',
  //         },
  //         {
  //           title: 'Vehicles Dispatched Summary',
  //           childRoute: 'vehicles-dispatched-summary',
  //         },
  //         {
  //           title: 'Fleet Partner Wallet',
  //           childRoute: 'fleet-partner-wallet',
  //         },
  //         {
  //           title: 'Vehicles Movement Dispatched',
  //           childRoute: 'vehicles-movement-dispatched',
  //         },
  //       ],
  //     },
  //   ],
  // },

  // {
  //   category: 'Finance & Accounts (F&C)',
  //   feature: 'Accounts',
  //   image: accounts_icon,
  //   mainRoute: '/accounts',
  //   subMenu: [
  //     {
  //       title: 'Summary Report',
  //       subRoute: 'summary-report',
  //     },
  //     {
  //       title: 'Merchant Cod Account',
  //       subRoute: 'merchant-cod-report',
  //     },
  //     {
  //       title: 'GIGGO',
  //       subRoute: 'giggo',
  //       childMenu: [
  //         {
  //           title: 'GO Summary',
  //           childRoute: 'report',
  //         },
  //         {
  //           title: 'GO Sales',
  //           childRoute: 'sales',
  //         },
  //         {
  //           title: 'Go Partners Payout',
  //           childRoute: 'partner-payout',
  //         },
  //       ],
  //     },
  //     {
  //       title: 'Expenditure',
  //       subRoute: 'expenditure',
  //     },
  //     {
  //       title: 'Cancel Shipments',
  //       subRoute: 'cancelled-shipments',
  //     },
  //     {
  //       title: 'Wallet Option',
  //       subRoute: 'wallet',
  //       childMenu: [
  //         {
  //           title: 'Wallet',
  //           childRoute: '',
  //         },
  //         {
  //           title: 'Wallet Credit History',
  //           childRoute: 'credit-history',
  //         },
  //         {
  //           title: 'Wallet Transactions',
  //           childRoute: 'transactions',
  //         },
  //       ],
  //     },
  //     {
  //       title: 'Bank Deposits',
  //       subRoute: 'bank-deposits',
  //       childMenu: [
  //         {
  //           title: 'Shipment Sales Deposits',
  //           childRoute: 'shipment-sales',
  //         },
  //         {
  //           title: 'COD Collection Deposits',
  //           childRoute: 'cod',
  //         },
  //         {
  //           title: 'Demurrage Collection Deposits',
  //           childRoute: 'demurrage',
  //         },
  //         {
  //           title: 'Transfer Collection Deposits',
  //           childRoute: 'transfer-payments',
  //         },
  //         {
  //           title: 'POS Collection Deposits',
  //           childRoute: 'pos-payments',
  //         },
  //       ],
  //     },
  //     {
  //       title: 'COD Option',
  //       subRoute: 'cod',
  //       childMenu: [
  //         {
  //           title: 'COD Balance',
  //           childRoute: 'balance',
  //         },
  //         {
  //           title: 'COD Accounts',
  //           childRoute: 'transactions',
  //         },
  //         {
  //           title: 'COD Unprocessed',
  //           childRoute: 'unprocessed-payments',
  //         },
  //         {
  //           title: 'COD Pending',
  //           childRoute: 'pending-payments',
  //         },
  //         {
  //           title: 'COD Processed',
  //           childRoute: 'processed-payments',
  //         },
  //       ],
  //     },
  //     {
  //       title: 'COD Pay Out Option',
  //       subRoute: 'cod-payout',
  //       childMenu: [
  //         {
  //           title: 'New COD Pay Out',
  //           childRoute: 'new-list',
  //         },
  //         {
  //           title: 'Paid COD Lists',
  //           childRoute: 'paid-list',
  //         },
  //       ],
  //     },
  //     {
  //       title: 'Wallet Payment Log',
  //       subRoute: 'wallet-payment-log',
  //     },
  //     {
  //       title: 'Delivery Waybill',
  //       subRoute: 'delivery-manifest-waybill',
  //     },
  //     {
  //       title: 'Bills Payment Summary Report',
  //       subRoute: 'bill-payment-summary',
  //     },
  //     {
  //       title: 'Bills Payment Transactions Report',
  //       subRoute: 'bill-payment-transactions',
  //     },
  //     {
  //       title: 'Wallet Transaction History',
  //       subRoute: 'wallet-transaction-history',
  //     },
  //     {
  //       title: 'Quick Quotes',
  //       subRoute: 'quick-quotes',
  //     },
  //     {
  //       title: "Int'l Quick Quotes",
  //       subRoute: 'international-quick-quotes',
  //     },
  //     {
  //       title: 'Forex Transaction History',
  //       subRoute: 'forex-transaction-history',
  //     },
  //     {
  //       title: 'Cellulant Settlement',
  //       subRoute: 'cellulant-settlement',
  //     },
  //     {
  //       title: 'Transfer Management',
  //       subRoute: 'transfer-management',
  //     },
  //     {
  //       title: 'Vehicles Dispatched',
  //       subRoute: 'vehicles-dispatched',
  //     },
  //     {
  //       title: 'Dispatches By Vehicle Number',
  //       subRoute: 'dispatches-vehicles-number',
  //     },
  //     {
  //       title: 'Wallet Report Summary',
  //       subRoute: 'wallet-report-summary',
  //     },
  //     {
  //       title: 'Staff Reroute Management',
  //       subRoute: 'staff-reroute',
  //     },
  //     {
  //       title: 'View JobCards',
  //       subRoute: 'job-cards',
  //     },
  //     {
  //       title: 'Upload File For Corporate Debt',
  //       subRoute: 'corporate-debt-file-upload',
  //     },
  //     {
  //       title: 'Captain Salary',
  //       subRoute: 'captain-salary',
  //     },
  //     {
  //       title: 'Region Daily Sales',
  //       subRoute: 'regional-shipment-sales',
  //     },
  //     {
  //       title: 'Exchange Rate Calculator',
  //       subRoute: 'exchange-rate-calculator',
  //     },
  //     {
  //       title: '',
  //       subRoute: '',
  //     },
  //   ],
  // },

  // {
  //   feature: 'Delivery Manifest',
  //   image: delivery_manifest_icon,
  //   mainRoute: '/delivery-manifests',
  //   subMenu: [
  //     {
  //       title: 'Manifests',
  //       subRoute: '',
  //     },
  //     {
  //       title: 'Waybills',
  //       subRoute: 'waybills',
  //     },
  //   ],
  // },

  // {
  //   category: 'ecommerce',
  //   feature: 'ecommerce',
  //   image: ecommerceIcon,
  //   mainRoute: '/ecommerce',
  //   subMenu: [
  //     { title: 'account customer', subRoute: 'account-customer' },
  //     { title: 'shipment report', subRoute: 'shipment-report' },
  //     {
  //       title: 'class customer management',
  //       subRoute: 'class-customers',
  //     },
  //     { title: 'create customer', subRoute: 'create-customer' },
  //     { title: 'ecommerce overdue', subRoute: 'ecommerce-overdue' },
  //     {
  //       title: 'pending ecommerce request',
  //       subRoute: 'pending-ecommerce-request',
  //     },
  //     { title: 'COD shipments', subRoute: 'cod-shipments' },
  //     { title: 'generate report', subRoute: 'generate-report' },
  //     { title: 'assigned customers', subRoute: 'assigned-customers' },
  //     {
  //       title: 'overall assigned customers',
  //       subRoute: 'overall-assigned-customers',
  //     },
  //     {
  //       title: 'assign Class Subscription',
  //       subRoute: 'assign-class-subscription',
  //     },
  //     {
  //       title: 'ecommerce delayed delivery report',
  //       subRoute: 'ecommerce-delayed-delivery-report',
  //     },
  //     { title: 'COD collection deposit', subRoute: 'cod-collection-deposit' },
  //     { title: 'delayed delivery', subRoute: 'delayed-delivery' },
  //     {
  //       title: 'confirm COD wallet transfer',
  //       subRoute: 'confirm-cod-wallet-transfer',
  //     },
  //     { title: 'COD shipment status', subRoute: 'cod-shipment-status' },
  //     { title: 'cellulant settlement', subRoute: 'cellulant-settlement' },
  //   ],
  // },

  // {
  //   category: 'personal shopping',
  //   feature: 'personal shopping',
  //   image: personalShoppingIcon,
  //   mainRoute: '/personal-shopping',
  //   subMenu: [{ title: 'order request', subRoute: 'order-request' }],
  // },

  // {
  //   feature: 'Shipments Tracking',
  //   category: 'utility components',
  //   image: world_explore_icon,
  //   mainRoute: '/shipments-tracking',
  //   subMenu: [
  //     {
  //       title: 'GIGLS Tracker',
  //       subRoute: '',
  //     },
  //     {
  //       title: 'Manual Scan',
  //       subRoute: 'manual-scan',
  //     },
  //     {
  //       title: 'Gun Scan',
  //       subRoute: 'gun-scan',
  //     },
  //     {
  //       title: 'Shipment Manifests',
  //       subRoute: 'manifests',
  //     },
  //     {
  //       title: 'Shipment Activities',
  //       subRoute: 'activities',
  //     },
  //     {
  //       title: 'Shipment Report',
  //       subRoute: 'report',
  //     },
  //   ],
  // },

  // {
  //   feature: 'Report',
  //   category: 'Report Center',
  //   image: reportsIcon,
  //   mainRoute: '/report-center',
  //   subMenu: [
  //     {
  //       title: 'Generate Report',
  //       subRoute: 'generate-report',
  //     },
  //     {
  //       title: 'Shipment Progress Summary',
  //       subRoute: 'shipment-progress-summary',
  //     },
  //     {
  //       title: 'Manifest Search',
  //       subRoute: 'manifest-search',
  //     },
  //     {
  //       title: 'Change Manifest Type',
  //       subRoute: 'change-manifest-type',
  //     },
  //   ],
  // },

  // {
  //   feature: 'GIGGO Processing',
  //   category: 'GIGGO Processing',
  //   image: giggi_processing_icon,
  //   mainRoute: '/giggo',
  //   subMenu: [
  //     {
  //       title: 'Batched Preshipment',
  //       subRoute: 'batch-preshipment-mobile',
  //     },
  //     {
  //       title: 'Express Drop Off',
  //       subRoute: 'express-dropoff',
  //     },
  //     {
  //       title: 'Xpress Drop-Off Plus',
  //       subRoute: 'customer-express-dropoff',
  //     },
  //     {
  //       title: 'Capture Shipment',
  //       subRoute: 'capture-shipment',
  //     },
  //     {
  //       title: 'Shipment TAT',
  //       subRoute: 'shipment-tat',
  //     },
  //     {
  //       title: 'Shipment Delivery TAT',
  //       subRoute: 'shipment-delivery-tat',
  //     },
  //     {
  //       title: 'Update GIGGO Partner',
  //       subRoute: 'update-partner',
  //     },
  //   ],
  // },

  // {
  //   feature: 'Pickup Processing',
  //   category: 'Pickup Processing',
  //   image: giggi_processing_icon,
  //   mainRoute: '/pickup-processing',
  //   subMenu: [
  //     {
  //       title: 'GIGGO Dashboard',
  //       subRoute: 'giggo-dashboard'
  //     },
  //     {
  //       title: 'GO Partners ',
  //       subRoute: 'go-partners'
  //     },
  //     {
  //       title: 'Heat map',
  //       subRoute: 'go-partners-heatmap'
  //     },
  //     {
  //       title: 'Partners confirmation ',
  //       subRoute: 'partners-confirmation'
  //     },
  //     {
  //       title: 'Pending partners',
  //       subRoute: 'pending-partners'
  //     },
  //     {
  //       title: 'Riders rating',
  //       subRoute: 'riders-rating'
  //     },
  //     {
  //       title: 'GIGGO Progress',
  //       subRoute: 'giggo-progress-report'
  //     },
  //     {
  //       title: 'Deactivate Partner',
  //       subRoute: 'deactivate-partner'
  //     },
  //     {
  //       title: 'External Shipments Log',
  //       subRoute: 'external-shipment-log'
  //     },
  //     {
  //       title: 'Confirm dropoff',
  //       subRoute: 'confirm-dropoff'
  //     },
  //     {
  //       title: 'Pending Node Shipment',
  //       subRoute: 'pending-node-shipment'
  //     },
  //     {
  //       title: 'Place Locations',
  //       subRoute: 'place-locations'
  //     },
  //     {
  //       title: 'Shipment Locations',
  //       subRoute: 'shipment-locations'
  //     },
  //   ]
  // },

  // {
  //   category: 'international processing',
  //   feature: 'international',
  //   image: flight_icon,
  //   mainRoute: '/international',
  //   subMenu: [
  //     {
  //       title: 'international shipment',
  //       subRoute: 'international-shipment',
  //     },
  //     {
  //       title: 'update international shipment mobile',
  //       subRoute: 'update-international-shipment-mobile',
  //     },
  //     {
  //       title: 'arrived shipment',
  //       subRoute: 'arrived-shipment',
  //     },
  //     {
  //       title: 'enroute shipment tracking',
  //       subRoute: 'enroute-shipment-tracking',
  //     },
  //     {
  //       title: 'inbound shipment',
  //       subRoute: 'inbound-shipment',
  //     },
  //   ],
  // },

  // {
  //   feature: 'Captain Management',
  //   image: truck,
  //   mainRoute: '/captain-management',
  //   category: 'Captain Management',
  //   subMenu: [
  //     { title: 'Captain Transactions', subRoute: 'captain-transactions' },
  //     { title: 'Fleets Make', subRoute: 'fleet-make' },
  //     {
  //       title: 'Fleets Model',
  //       subRoute: 'fleet-model',
  //     },
  //     {
  //       title: 'Fleets',
  //       subRoute: 'fleets',
  //     },
  //     {
  //       title: 'Movement Manifest',
  //       subRoute: 'movement-manifest',
  //     },
  //     {
  //       title: 'Generate Movement Manifest',
  //       subRoute: 'generate-movement-manifest',
  //     },
  //   ],
  // },

  // {
  //   feature: 'Shipments Updates',
  //   image: truck,
  //   mainRoute: '/shipment-update',
  //   category: 'Shipments Update',
  //   subMenu: [
  //     { title: 'Cancelled Shipments', subRoute: 'cancelled-shipment' },
  //     { title: 'Reroute Shipment', subRoute: 'reroute-shipment/:id' },
  //     {
  //       title: 'Initialte Cancel Shipment',
  //       subRoute: 'initiate-cancel-shipment',
  //     },
  //   ],
  // },

  // {
  //   category: 'PickUp Request',
  //   feature: 'PickUp Request',
  //   image: storeKeepingIcon,
  //   mainRoute: '/pickup-request',
  //   subMenu: [
  //     {
  //       title: 'Mobile Pickup Requests',
  //       subRoute: 'mobile-pickup-requests',
  //     },
  //     {
  //       title: 'Batched Preshipment',
  //       subRoute: 'batched-preshipment',
  //     },
  //     {
  //       title: 'Pending Mobile Requests',
  //       subRoute: 'pending-mobile-requests',
  //     },
  //     {
  //       title: 'Update GIGGo Shipment',
  //       subRoute: 'update-giggo-Shipment',
  //     },
  //     {
  //       title: 'Pickup Manifest',
  //       subRoute: 'pickup-manifest',
  //     },
  //     {
  //       title: 'Remove From Queue',
  //       subRoute: 'remove-from-queue',
  //     },
  //     {
  //       title: 'Manual Wallet Charge',
  //       subRoute: 'manual-wallet-charge',
  //     },
  //     {
  //       title: 'Riders Delivery Progress',
  //       subRoute: 'riders-delivery-progress',
  //     },
  //     {
  //       title: 'Delivery Number',
  //       subRoute: 'delivery-number',
  //     },
  //     {
  //       title: 'Go Partners Payment',
  //       subRoute: 'go-partners-payment'
  //     },
  //     {
  //       title: 'Assign Shipment',
  //       subRoute: 'assign-shipment',
  //     },
  //     {
  //       title: 'Cancelled Shipment Report',
  //       subRoute: 'cancelled-shipment-report',
  //     },
  //   ],
  // },

  // {
  //     category: "viva operations",
  //     feature: "VIVA operations",
  //     image: viva_icon,
  //     mainRoute: "/viva-operations",
  //     subMenu: [
  //       {
  //         title: 'shipment requests',
  //         subRoute: 'shipment-requests',
  //       },
  //       {
  //         title: 'received shipment requests',
  //         subRoute: 'received-shipment-requests',
  //       },
  //       {
  //         title: 'export shipment',
  //         subRoute: 'export-shipment',
  //       },
  //       {
  //         title: 'cargo manifest',
  //         subRoute: 'cargo-manifest',
  //       },
  //       {
  //         title: 'register item',
  //         subRoute: 'register-item',
  //       },
  //       {
  //         title: 'unidentified shipment item',
  //         subRoute: 'unidentified-shipment-request',
  //       },
  //     ]
  // },

  // {
  //   feature: 'GIGGO Admin Options',
  //   image: reportsIcon,
  //   mainRoute: '/giggo-admin',
  //   category: 'Shipments Update',
  //   subMenu: [
  //     {
  //       title: 'Credit Partner',
  //       subRoute: 'credit-partner'
  //     }
  //   ]
  // },

  // {
  //     category: "sales representative",
  //     feature: "sales representative",
  //     image: sales_rep_icon,
  //     mainRoute: "/sales-representative",
  //     subMenu: [
  //       {
  //         title: 'special billing weight',
  //         subRoute: 'special-billing-weight',
  //       },
  //       {
  //         title: 'special billing default',
  //         subRoute: 'special-billing-default',
  //       },
  //     ]
  // },

  // {
  //     category: "",
  //     feature: "dashboard",
  //     image: dashboardIcon,
  //     mainRoute: "/",
  //     subMenu: []
  // },

]