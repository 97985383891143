import { down_white_arrow, whiteArrowtRightIcon } from 'assets'
import { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { ISidebarSubmenuItem } from 'utils/interfaces'

type ISidebarDropdown = {
    item: ISidebarSubmenuItem
    open?: string
    id: number
    setActivePage: (e: string) => void
    activePage: string
    newMenu?: boolean
    mainRoute: string
    subRoute: string
}


export const SidebarDropdown = ({item, newMenu, mainRoute, subRoute, activePage, setActivePage}: ISidebarDropdown) => {
    const navigate = useNavigate()
    const {pathname} = useLocation()
    
    const [hoverState, setHoverState] = useState(false)
    
  return (
    <div className={``}
        onMouseOver={() => setHoverState(true)}
        onMouseOut={() => setHoverState(false)}
        
    >
       <div className='sub-menu h-[2.3rem] flex items-center pl-[2.3rem] pr-2 cursor-pointer hover:text-col_e84e40'
       onClick={() => {
            if (!item.childMenu?.length) {
                setActivePage(item?.title)
            navigate(`${mainRoute}/${subRoute}`)
            }
        }}
       >
        <span className={`capitalize text-[11.5px] leading-[13px] hover:text-col_e84e40`}>{item?.title}</span>
            {newMenu && !hoverState && 
                <span className={`bg-col_6fcf97 text-col_fff border border-x-col_fff px-2 py-[0.15rem] rounded-[0.812rem] text-[0.712rem] leading-[1rem] ml-auto mr-1`}>
                    new
                </span>
            }
            {hoverState && activePage !== item.title ? <img src={!item?.childMenu?.length ?  whiteArrowtRightIcon : ""} alt="" className={`ml-auto mr-3`} /> : null}

            {!item.childMenu?.length && pathname.split("/")[2] === subRoute  ? <img src={whiteArrowtRightIcon} alt="" className={`ml-auto mr-1`} /> : null}
            {
                item.childMenu?.length ? <img src={down_white_arrow} alt="" className={`ml-auto mr-1`} /> : <></>
            }
       </div>
       {item.childMenu?.length && item.childMenu.map((ele, i: number) => (
        <div key={i} 
        onClick={() => {
            setActivePage(item?.title)
            navigate(`${mainRoute}/${subRoute}/${ele.childRoute}`)
        }}
        className='h-[2.3rem]  flex items-center pl-[3.5rem] pr-1 cursor-pointer hover:text-col_e84e40 '>
            {/* <img src={whiteArrowtRightIcon} alt="" /> */}
            <span className={`capitalize text-[11.5px] leading-[13px]`}>{ele?.title}</span>
            {newMenu && !hoverState && 
                <span className={`bg-col_6fcf97 text-col_fff border border-x-col_fff px-2 py-[0.15rem] rounded-[0.812rem] text-[0.712rem] leading-[1rem] ml-auto mr-1`}>
                    new
                </span>
            }
            {/* {hoverState && activePage !== ele.title ? <img src={whiteArrowtRightIcon} alt="" className={`ml-auto mr-3`} /> : null} */}
            {pathname.split("/")[3] === ele.childRoute  ? <img src={whiteArrowtRightIcon} alt="" className={`ml-auto mr-1`} /> : null}
       </div>
       ))}
    </div>
  )
}
