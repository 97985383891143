import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SHIPMENT_SENDER_TYPE } from "utils/enums";
import { ICaptureShipmentStateProps } from "utils/interfaces";


export const captureShipmentStates: ICaptureShipmentStateProps = {
    senderType: SHIPMENT_SENDER_TYPE.individual_sender,
    destinationCountry: "",
    deliveryOptions: "",
    pickUpOption: "",
    destinationState: "",
    locality: "",
    shipmentSpecificationState: [],
    shipmentDescription: "",
    packageType: "",
    manufacturerCountry: "",
    blackBook: "",
    shipmentValue: "",
    shipmentKind: "",
    shipmentType: "",
    customerCode: "",
}

export const captureShipmentSlice = createSlice({
    name: "capture-shipment",
    initialState: captureShipmentStates,
    reducers: {
        setSenderType: (state, action: PayloadAction<string>) => {
            state.senderType = action.payload
        },
        setDestinationCountry: (state, action: PayloadAction<string>) => {
            state.destinationCountry = action.payload
        },
        setDeliveryOptions: (state, action: PayloadAction<string>) => {
            state.deliveryOptions = action.payload
        },
        setPickupOptions: (state, action: PayloadAction<string>) => {
            state.pickUpOption = action.payload
        },
        setDestinationState: (state, action: PayloadAction<string>) => {
            state.destinationState = action.payload
        },
        setLocality: (state, action: PayloadAction<string>) => {
            state.locality = action.payload
        },
        addShipmentSpecification: (state, action: PayloadAction<any>) => {
            state.shipmentSpecificationState = [...state.shipmentSpecificationState, action.payload]
        },
        removeShipmentSpecification: (state, action: PayloadAction<string | number>) => {
            console.log("spec removed-->>>>>>")
            const isItemExist = state.shipmentSpecificationState.find((item: any) => item.id === action.payload)
            console.log("<<<<< isItemExist >>>>> ", isItemExist)
            state.shipmentSpecificationState = state.shipmentSpecificationState.filter(item => item.id !== action.payload)
        },
        addShipmentSpecificationContent: (state, action: PayloadAction<{key: string, val: string}>) => {
            const isItemExist = state.shipmentSpecificationState.find((item: any) => item.id === action.payload)
            if (isItemExist) {
                const value = action.payload.val
                const key = action.payload.key
                //@ts-ignore
                isItemExist[`${key}`] = value
            }
        },
        setPackageType: (state, action: PayloadAction<string>) => {
            state.packageType = action.payload
        },
        setManufacturerCountry: (state, action: PayloadAction<string>) => {
            state.manufacturerCountry = action.payload
        },
        setBlackBook: (state, action: PayloadAction<string>) => {
            state.blackBook = action.payload
        },
        setShipmentValue: (state, action: PayloadAction<string>) => {
            state.shipmentValue = action.payload
        },
        setshipmentKind: (state, action: PayloadAction<string>) => {
            state.shipmentKind = action.payload
        },
        setShipmentType: (state, action: PayloadAction<string>) => {
            state.shipmentType = action.payload
        },
        setCustomerCode: (state, action: PayloadAction<string>) => {
            state.customerCode = action.payload
        },
    }
})

export const captureShipmentActions = captureShipmentSlice.actions
export const captureShipmentReducer = captureShipmentSlice.reducer