import { useMutation } from '@tanstack/react-query'
import { resetPasswordpApi } from 'apiRequest/auth/authentication'
import { back_arrow, btn_arrow_right_white } from 'assets'
import { Button } from 'components'
import { TextInput } from 'components/inputs/TextInput'
import { useState } from 'react'
import { useNavigate } from 'react-router'
import { toast } from 'react-toastify'



export const ResetPassword = () => {
    const [password, setPassword] = useState("")
    const [confirmPassword, setConfirmPassword] = useState("")
    const navigate = useNavigate()
    const {mutate} = useMutation({
      mutationFn: resetPasswordpApi,
      onError: (error: Error) => {
        console.log("error=== ", error);
      },
      onSuccess: (res: any) => {
        console.log("res---- ", res);
        navigate("/")
        // navigate("/shipments/capture-shipments")
      }
    })
  
    const changePassword = () => {
      console.log("login fxn clicked");
      if(confirmPassword === password){
        mutate({
          new_password: password})
      }else{
        toast(
          "Password doesn't match",
          {
              type: "error",
              position: "top-right",
              autoClose: 5000,
              theme: "colored"
          }
      )
      return;
      }
     
    }

  return (
    <div className="px-2">
    <p className='mb-6 text-[0.831rem] font-[500] text-col_525252'>
      Your new password must be atleast 8 characters
    </p>
    <TextInput
      label='Password'
      placeholder='Enter your Password'
      value={password}
      setValue={setPassword}
      onChange={(e) => setPassword(e.target.value)}
      passwordInput={true}
      className="w-full mb-6"
    />

     <TextInput
      label='confirm password'
      placeholder='Confirm your password'
      value={confirmPassword}
      setValue={setConfirmPassword}
      onChange={(e) => setConfirmPassword(e.target.value)}
      passwordInput={true}
      className="w-full mb-6"
    />

    <Button
      text="Reset Password"
      suffixIcon={btn_arrow_right_white}
      onClick={() => changePassword()}
      //onClick={() => navigate("/")}
      className='w-full mt-8 text-col_ffffff px-6 py-4 flex items-center justify-center cursor-pointer text-[0.83rem] font-[600] rounded-[0.475rem] bg-col_e84e40'
    />

    <div className=' flex items-center justify-center gap-2 mt-6 '>
        <img src={back_arrow} alt="" className='cursor-pointer' onClick={() => navigate(-1)} />
        <span className='cursor-pointer font-fontInter text-txt_087_normal text-[#525252]' onClick={() => navigate(-1)}>Back to login</span>
    </div>
  </div>
  )
}