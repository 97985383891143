interface ICurrencyFormat {
	amount: string
	currency: string
}

export const _getUser = () => {
	const stringifiedUser = sessionStorage.getItem('agility_user');
	if (stringifiedUser && stringifiedUser !== 'undefined') {
		const localUser: any = JSON.parse(stringifiedUser);
		return localUser;
	}
	return null;
};

export const _clearData = ({ pushToLogin = true }) => {
	pathName() && sessionStorage.clear();
	if (pushToLogin) {
		window.location.href = "/";
	}
	return false;
};

export const pathName = () => typeof window !== "undefined" && window;

export const _getToken = () => {
	const token = pathName() && sessionStorage.getItem("agility_token");

	return token;
};

export const _isAnEmptyObject = (obj: any) => {
	for (const key in obj) {
		if (obj?.prototype?.hasOwnProperty?.call(key)) return false
		else return false
	}
	return true
}

export const _isUserLoggedIn = (): boolean => {
	const user = _getUser();
	const token = _getToken()
	if (!_isAnEmptyObject(user) && token !== undefined) {
		return true;
	}
	return false;
};

export const formatMoney = (amount: any, isDecimal = true) => {
	if (!amount) return null;

	const formatter = isDecimal
		? new Intl.NumberFormat('en-US', {
				minimumFractionDigits: 2,
				maximumFractionDigits: 2,
		  })
		: new Intl.NumberFormat('en-US', {});

	return formatter.format(amount);
};

export const formatCurrency = ({amount, currency}: ICurrencyFormat) => {
	if (amount !== "") {
		const formattedNumber = new Intl.NumberFormat('en-US', { 
      currency: currency, 
      minimumFractionDigits: 2,
    }).format(parseInt(amount))
		return formattedNumber
	}
	
	else return null
}

export const formatNumber = (value: number) => {
	return new Intl.NumberFormat().format(value)
}

const debounce = <T extends (...args: any[]) => void>(fn: T) => {
    let frame: number;
  
    return (...params: Parameters<T>) => {
      if (frame) {
        cancelAnimationFrame(frame);
      }
  
      frame = requestAnimationFrame(() => {
        fn(...params);
      });
    };
};
  
export const storeScroll = () => {
    document.documentElement.dataset.scroll = window.scrollY.toString();
};

export function getRandomElement <T>(arr: Array<T>): T {
  return arr[Math.floor(Math.random() * arr.length)]
}

export const nairaSymbol = '\u20A6';

export const dollarSymbol = '\u0024';

export const cedisSymbol = '₵';

export const poundsSymbol = '£';

document.addEventListener('scroll', debounce(storeScroll), { passive: true });
  
storeScroll();