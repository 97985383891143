import { apiService } from "api/apiService"

export const loginApi = async (data: object) => {
    const payload = {...data}
    const response = await apiService.post({
        url: `user/login`,
        payload
    })
    return response
}
export const forgotPasswordApi = async (data: object) => {
    const payload = {...data}
    const response = await apiService.post({
        url: `user/forgotPassword`,
        payload
    })
    return response
}
export const validateOtpApi = async (data: object) => {
    const payload = {...data}
    const response = await apiService.post({
        url: `user/validateOtp`,
        payload
    })
    return response
}
export const resetPasswordpApi = async (data: object) => {
    const payload = {...data}
    const response = await apiService.post({
        url: `/user/resetPassword`,
        payload
    })
    return response
}