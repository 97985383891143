import {configureStore} from "@reduxjs/toolkit"
import { captureShipmentReducer } from "./slices/captureShipment/captureShipmentSlice"
import { agilityReducer } from "./slices/utils/utilSlice"
import authReducer from './slices/authLogin/authSlice';
import { IAgilityProps, ICaptureShipmentStateProps, ILoginProps } from "utils/interfaces"

export type IStoreProps = {
    captureShipmentReducer: ICaptureShipmentStateProps
    agilityReducer: IAgilityProps
    authReducer: ILoginProps
}

export const store = configureStore({
    reducer: {
        captureShipmentReducer: captureShipmentReducer,
        agilityReducer: agilityReducer,
        auth: authReducer
    }
})

export type IRootState = ReturnType<typeof store.getState>