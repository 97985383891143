
export enum ROUTES {
    home = "/",
    login = "/login",
    forgot_password = "/forgot-password",
    reset_password_otp = "/reset-password-otp",
    reset_password = "/reset-password",
    dashboard = "/dashboard",
    
}

export enum SHIPMENTS_ROUTES {
    capture_shipments = "/shipments/capture-shipments",
    capture_shipments_payment = "/shipments/capture-shipments/payment",
    capture_intl_shipments = "/shipments/capture-intl-shipments",
    capture_intl_shipments_payment = "/shipments/capture-intl-shipments/payment",
    daily_sales = "/shipments/daily-sales",
    delivery_locations = "/shipments/delivery-locations",
    shipment_details = "/shipments/shipment-details",
    shipements_overdue_shipments = "/shipments/overdue-shipments",
    create_return_shioments = "/shipments/create-return-shipment",
    view_return_shipments = "/shipments/view-return-shipment",
    return_shipments = "/shipments/return-shipment",
    packing_list = "/shipments/packing-list",
    create_packing_list = "/shipments/create-packing-list",
    expenditure = "/shipments/expenditure",
    add_expenditure = "/shipments/add-expenditure",
    ecommerce_overdue_shipments = "/shipments/ecommmerce-overdue-shipements",
    bank_processing_order = "/shipments/bank-processing-order",
    validate_waybill = "/shipments/validate-waybill",
    cod_processing_order = "/shipments/cod-processing-order",
    demurrage_processing_order = "/shipments/demurrage-processing-order",
    transfer_management = "/shipments/transfer-management",
}

export enum PROCESSING_AND_PACKAGING_ROUTES {
    group_shipment_view = "/processing-and-packaging/group-shipment-view",
    add_shipment_view = "/processing-and-packaging/group-shipment/add-group-shipment",
    gateway_activity = "/processing-and-packaging/gateway-activity",
    manifest = "/processing-and-packaging/manifest",
    generate_manifest = "/processing-and-packaging/manifest/generate-manifest",
    manifest_detail = "/processing-and-packaging/manifest/manifest-detail",
    movement_manifest = "/processing-and-packaging/movement-manifest",
    generate_movement_manifest = "/processing-and-packaging/movement-manifest/generate-movement-manifest",
    movement_manifest_detail = "/processing-and-packaging/movement-manifest/movement-manifest-detail",
    expected_movement_manifest = "/processing-and-packaging/expected-movement-manifest",
    quick_quote = "/processing-and-packaging/quick-quote",
    international_quick_quote = "/processing-and-packaging/international-quick-quote",
    ecommerce_quick_quote = "/processing-and-packaging/ecommerce-quick-quote",
    super_manifest = "/processing-and-packaging/super-manifest"
}

export enum HUB_PROCESSING_ROUTES {
    hub_manifest = '/hub-processing/manifest',
    hub_manifest_assignment = '/hub-processing/manifest-assignment',
    hub_delivery_report = '/hub-processing/delivery-report'
}

export enum SHIPMENTS_TRACKING_ROUTES {
    shipments_tracker = '/shipments-tracking',
    manual_scan = '/shipments-tracking/manual-scan',
    gun_scan = '/shipments-tracking/gun-scan',
    manifests = '/shipments-tracking/manifests',
    activities = '/shipments-tracking/activities',
    report = '/shipments-tracking/report'
}

export enum GIGGO_ROUTES {
    batch_preshipment = '/giggo/batch-preshipment-mobile',
    express_dropoff = '/giggo/express-dropoff',
    customer_express_dropoff = '/giggo/customer-express-dropoff',
    capture_shipment = '/giggo/capture-shipment',
    shipment_tat = '/giggo/shipment-tat',
    shipment_delivery_tat = '/giggo/shipment-delivery-tat',
    update_partner = '/giggo/update-partner',
    giggo_payment = '/giggo/payment',
}

export enum COD_ACCOUNTS_ROUTES {
    cod_transactions = '/accounts/cod/transactions',
    cod_balance = '/accounts/cod/balance',
    cod_pending_payments = '/accounts/cod/pending-payments',
    cod_processed_payments = '/accounts/cod/processed-payments',
    cod_unprocessed_payments = '/accounts/cod/unprocessed-payments',
}

export enum ACCOUNTS_COD_PAYOUT {
    new_list = '/accounts/cod-payout/new-list',
    paid_out_list = '/accounts/cod-payout/paid-list',
}

export enum ACCOUNTS_ROUTES {
    quick_quotes = '/accounts/quick-quotes',
    international_quick_quotes = '/accounts/international-quick-quotes',
}
export enum INTERNATIONAL {
    international_shipment = "/international/international-shipment",
    update_international_shipment = "/international/update-international-shipment",
}

export enum USER_MANAGEMENT {
    user = "/user-management/users",
    create_user = "/user-management/create-user",
    customers = "/user-management/customers",
    partners = "/user-management/partners",
    gigl_roles = "/user-management/gigl-roles",
    system_roles = "/user-management/system-roles",
    access_points = "/user-management/access-points",
    wallet = "/user-management/wallet"
}

export enum SALES_ROUTES {
    create_customer = "/sales/create-customer",
    corporate_customer = "/sales/corporate-customer",
    edit_corporate_customer = "/sales/corporate-customer/edit",
    capture_sales_shipment = "/sales/capture-shipment",
    customer_transactions = "/sales/customer-transactions",
    customer_invoice = "/sales/customer-invoice",
}

export enum STORE_KEEPING {
    capture_sales_shipment = "/store-keeping/capture-shipment",
}


export enum CUSTOMER_CARE_ROUTES {
    create_incidence_report = "/customer-care/create-incidence-report",
    view_incidence_report = "/customer-care/incidence-report",
    quick_quotes = "/customer-care/quick-quotes",
    intl_quotes = "/customer-care/intl-quotes",
}

export enum VIVA_ROUTES {
    shipment_requests = "/viva-operations/shipment-requests",
    unidentified_shipment_request = '/viva-operations/unidentified-shipment-request',
    export_shipment = '/viva-operations/export-shipment',
    cargo_manifest = 'cargo-manifest'

}

export enum SALES_REPRESENTATIVE {
    special_billing_weight = '/sales-representative/special-billing-weight',
    special_billing_default = '/sales-representative/special-billing-default'
}
